/* eslint-disable no-unused-vars */
export enum PaymentType {
  Card,
  GooglePay,
  ApplePay,
  BankAccount,
  PayPal,
  Wallet,
}

export enum PaymentMethodsType {
  CreditCard = 844060000,
  BankAccount = 844060001,
  PaymentMethodType = 100000000,
}

/* eslint-disable no-unused-vars */
export enum PaymentGatewayType {
  Moneris = 844060000,
  Stripe = 844060001,
  Iats = 844060002,

  // *** Adyen, WorldPay and GlobalPayment are missing from the Option Set file ***

  //      Moneris = 703650000,
  //      Stripe = 703650001,
  //      Iats = 703650002,
  Adyen = 703650006,
  WorldPay = 703650004,
  GlobalPayment = 703650005,
  Paysafe = 703650000,
  Paya = 844060003,
  PTX = 844060004,
}

export enum PaymentGatewayEnviroment {
  Test = 0,
  Live,
}

/* eslint-disable no-unused-vars */
export enum PaymentIdentityType {
  Transaction = 0,
  PaymentSchedule,
}

export enum PaymentCountryContextCode {
  AU = 703650000,
  CA,
  FR,
  DE,
  NL,
  NO,
  CH,
  GB,
  US,
}

export enum CreditCardType {
  Visa = 844060000,
  MasterCard = 844060001,
  VisaDebit = 844060002,
  MasterCardDebit = 844060003,
  AmericanExpress = 844060004,
  DinersClub = 844060005,
  JCB = 844060006,
  Interact = 844060007,
  DiscoverCard = 844060008,
  UnionPay = 844060009,
  Unknown = 844060010,
}
