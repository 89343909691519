import axios, { AxiosRequestConfig } from 'axios';
import qs from 'qs';
import { objectToFormData } from '@utils/index';
import { authHeader } from '@utils/auth-header';
import {
  SelectedMembership,
  CustomQuestions,
  // CustomQuestionsCategories,
} from '@app/types';
import { PaymentIdentityType, SignUp, FrequencyType } from '@root/enums';
import {
  PageBackground,
  PageDetails,
  SupportDetails,
  TransactionCurrency,
  TermsOfReference,
} from '@root/interfaces';
import { CampaignPageTypes } from '@root/modules/campaign/campaignSlice';

export interface CampaignPageAction {
  campaignPageActionId?: string;
  transactionCurrencyId?: string;
  packageId?: string;
  display?: string;
  displayTitle?: string;
  message?: string;
  customAmount?: boolean;
  defaultAmount?: number;
  givingFrequencyCode?: number;
  smallImage?: string;
  cardImage?: PageBackground;
  minimumAmount?: number;
  order?: number;
  identifier?: string;
  transactionCurrency?: TransactionCurrency;
}

export interface CampaignPageDetails extends PageDetails {
  campaignPageId?: string;
  configurationId?: string;
  actionHeader?: string | null;
  campaignStart?: Date | string | null;
  campaignEnd?: Date | string | null;
  carouselImageIds?: PageBackground[];
  coverCostAmount?: number;
  coverCostPercentage?: number;
  clientInfo?: ClientInfo;
  daysLeft?: number;
  displayTitle?: string;
  embedVideo?: string;
  friendlyUrl: string;
  forceRedirectTiming: number;
  forceRedirectUrl: string;
  giftAidAcceptence?: string;
  givingFrequencyCode?: number;
  goalAmount?: number;
  goalPercentage?: number;
  goalShowAmount?: boolean;
  goalShowCountdown?: boolean;
  goalShowPercent?: boolean;
  goalShowPercentageRaised?: number;
  goalShowProgressBar?: boolean;
  goalShowSupporters?: boolean;
  goalSupporterAmount?: number;
  goalSupporters?: number;
  headerMessage?: string;
  heroBackgroundImage?: PageBackground;
  profileBackgroundImage?: PageBackground;
  largeImage?: string;
  message?: string;
  setCoverCosts?: boolean;
  setAcceptNotice?: boolean;
  setSignup?: boolean;
  showApple?: boolean;
  showCompany?: boolean;
  showCoverCosts?: boolean;
  showCreditCard?: boolean;
  showGiftAid?: boolean;
  showGoogle?: boolean;
  showInHonor?: boolean;
  showMembership?: boolean;
  showMatchDonation?: boolean;
  showPaymentNotice?: boolean;
  paymentNotice?: string;
  paymentNoticeMonthly?: string;
  paymentNoticeAnnual?: string;
  signup?: string;
  smallImage?: string;
  thankYou?: string;
  totalAmountRaised?: number;
  showHeader?: boolean;
  allowValidateAddress?: boolean;
  languageCode?: string;
  termsOfReference: TermsOfReference;
  transactionCurrency: TransactionCurrency;
  campaignPageActions?: CampaignPageAction[];
  event?: any;
  recaptchaSiteKey: string | null;
  globalPayGoogleMerchantId?: string;
  globalPayGoogleMerchantName?: string;
  globalPayMerchantId?: string;
  externalUrl?: string | null;
  fundraisingSignUpType?: string | null;
  fundraisingCampaignId?: string | null;
  fundraisingAppealId?: string | null;
  fundraisingPackageId?: string | null;
  imageModified?: boolean | null;
  messageModified?: boolean | null;
  messageApproved?: boolean | null;
  imageApproved?: boolean | null;
  allowIndividualCampaigns?: boolean | null;
  allowTeamCampaigns?: boolean | null;
  campaignPageTypeId?: CampaignPageTypes;
  profile?: any;
  parentCampaignPage?: CampaignPageDetails;
  parentCampaignPageId?: string | null;
  childCampaignPages?: ChildCampaignPageDetails[];
  campaignDonations?: CampaignDonations[];
  userProfile?: SimpleProfileViewModel;
  pageOwnerId?: string;
  allowToJoin?: boolean;
  membershipGroups?: any;
  removed?: boolean;
  active?: boolean;
  stateCode?: number;
  masterFriendlyUrl?: string;
  masterExternalUrl?: string;
  grandParentRemoved?: boolean;
  helpFurtherFooterMessage?: string;
  supporterMessage?: string;
  sponsorMessage?: string;
}

export interface ChildCampaignPageDetails {
  campaignPageId?: string;
  pageOwnerId?: string;
  displayTitle?: string;
  largeImage?: string;
  totalAmountRaised?: number;
  campaignPageTypeId?: CampaignPageTypes;
  friendlyUrl?: string;
  parentFriendlyUrl?: string | null;
  parentDisplayTitle?: string | null;
  externalUrl?: string;
  goalAmount?: number;
  message?: string | null;
  daysLeft?: number;
  allowToJoin?: boolean;
  currencySymbol?: string | null;
  campaignStart?: Date | string | null;
  campaignEnd?: Date | string | null;
  hasMember?: boolean;
  recaptchaSiteKey?: string | null;
  parentCampaignPage?: ChildCampaignPageDetails[];
  parentCampaignPageId?: string | null;
}

export interface SimpleProfileViewModel {
  firstName: string | null;
  lastName: string | null;
  profilePictureId: string | null;
  email: string | null;
}

export interface CampaignPageCRUDViewModel {
  name: string | null;
  message?: string | null;
  goal: number;
  campaignPageId: string;
  parentCampaignPageId: string;
  grandParentCampaignPageId?: string | null;
  parentCampaignPage?: CampaignPageDetails;
  campaignPageTypeId: CampaignPageTypes;
  endDateString?: string;
  startDateString?: string;
  mainPhoto?: string;
  displayJoinTheTeam?: boolean;
  isParentCampaign?: boolean;
  isNewCampaign?: boolean;
  uploadPhotoCampaign?: File | string;
}

export interface CampaignPageType {
  campaignPageTypeId: string | null;
  campaignPageTypeOptionSetId: number | null;
  campaignPageTypeIdName: string | null;
}

export interface BuyerDetails {
  organizationName: string;
  salutation: string;
  firstName: string;
  lastName: string;
  emailaddress1: string;
  telephone1: string;
  billingLine1?: string;
  billingLine2?: string;
  billingCity: string;
  billingStateorProvince: string;
  billingPostalCode: string;
  billingCountry?: string;
  dedicate?: string;
  employerMatchesDonationCheckbox?: boolean;
  nameOfHonouree?: string;
  recipientEmail?: string;
  specialMessage?: string;
  next?: string;
  canadaPostId?: string;
  anonymousCheckbox?: boolean;
}

export interface ClientInfo {
  code?: string;
  homePage?: string;
}

export interface CampaignDonations {
  displayName: string | null;
  messageOfSupport: string | null;
  hideDisplayName: boolean;
  bookDate: Date | string | null;
  amount: number;
}

export interface GetOrderTotal {
  coverCosts?: boolean;
  pageDetails?: PageDetails;
  donationAmount?: number;
  selectedMembership?: SelectedMembership;
  givingFrequency?: any;
}

export interface CampaignPagePost {
  campaignPageId: string;
  donationPageId?: string;
  campaignPageActionId?: string | null;
  packageId?: string | null;
  campaignId?: string | null;
  appealId?: string | null;
  designationId?: string | null;
  anonymousCheckbox?: boolean;
  amount: string | number;
  isRecurring: boolean;
  isoCurrencyCode: string;
  creditCardNo: string;
  expMM: string;
  expYY: string;
  cvc: string;
  bankName: string;
  bankAccNumber: string;
  routingNumber: string;
  bankTypeCode?: number;
  matchDonationCheckbox?: string;
  employerMatchesDonationCheckbox?: string;
  dedicate?: string;
  coverCostsCheckbox?: string;
  paymentNoticeCheckbox?: string;
  signUpCheckbox?: SignUp | string | null;
  costPercentage?: string;
  costAmount?: string;
  giftAidAcceptCheckbox?: string;
  giftAidClaimAmount?: number;
  organizationName?: string;
  myEmployer?: string;
  salutation?: string;
  familyId?: string;
  firstName: string;
  lastName: string;
  emailAddress1: string;
  billingStateOrProvince?: string;
  billingCountry?: string;
  billingLine1: string;
  billingLine2?: string;
  billingCity: string;
  billingPostalCode: string;
  telephone1?: string;
  recaptchaToken?: string;
  nameofHonouree?: string;
  recipientEmail?: string;
  specialMessage?: string;
  tributeAddressee?: string;
  tributeLine1?: string;
  tributeLine2?: string;
  tributeCity?: string;
  tributeStateOrProvince?: string;
  tributePostalCode?: string;
  tributeCountry?: string;
  tributeOptionsCode?: string | number | null;
  membershipAmount?: number;
  membershipAmountTax?: number;
  membershipBaseAmount?: number;
  membershipCategoryId?: string;
  constituentId?: string;
  payToken?: string;
  paymentType?: string;
  paymentStatus?: string;
  stripeId?: string;
  supportDetails?: SupportDetails;
  fundraisingTotal?: string;
  noOfSupporters?: number;
  frequencyType?: FrequencyType;
  name?: string;
  transactionDescription?: string;
  // preferenceQuestions?: CustomQuestionsCategories;
  preferenceQuestions?: CustomQuestions;
  isCompanyGift?: boolean;
  acknowledgementPaymentIdentityType?: PaymentIdentityType;
  resultCode?: string;
  dropIn?: boolean;
  adyenSessionId?: string;
  ptxStatusCode?: number | null;
  doubleTheDonation?: boolean;
  UTMParamsPost?: any;
  UTMCampaignId?: string;
  UTMCampaignSource?: string;
  UTMCampaignMedium?: string;
  UTMCampaignName?: string;
  UTMCampaignTerm?: string;
}

export interface AddressVerification {
  campaignPageId: string;
  companyName?: string;
  billingLine1?: string;
  billingLine2?: string;
  billingCity?: string;
  billingStateorProvince?: string;
  billingPostalCode?: string;
  billingCountry?: string;
  action: 'autocomplete' | 'verification';
  next?: string;
  canadaPostId?: string;
}

// export interface DonationGroupProps {
//   donationAmount: number;
//   givingFrequency: string;
// }

export interface JsonResponseViewModel {
  success: boolean;
  message?: string | null;
  data?: any | null;
  errorType?: string | null;
}

export async function getCampaignPageDetails(
  friendlyUrl: string,
  defaultDesignation?: string
) {
  const requestOptions: AxiosRequestConfig = {
    method: 'post',
    headers: authHeader(),
    url: '/campaigns/getCampaignPageDetails',
    data: qs.stringify({ friendlyUrl, des: defaultDesignation }),
  };
  const { data } = await axios(requestOptions);
  return data;
}

export async function getSupportMessages(friendlyUrl: string) {
  const requestOptions: AxiosRequestConfig = {
    method: 'post',
    headers: authHeader(),
    url: '/campaigns/getSupportMessages',
    data: qs.stringify({ friendlyUrl }),
  };
  const { data } = await axios(requestOptions);
  return data;
}

export async function createCampaign(
  campaignPageDetails: CampaignPageCRUDViewModel,
  campaignPhoto?: any
) {
  let newCampaignPhotoId = null;
  if (campaignPhoto) {
    const imgFormData = new FormData();
    imgFormData.append('file', campaignPhoto.file);

    const imgRequestOptions: any = {
      method: 'post',
      data: imgFormData,
      headers: authHeader(),
      url: '/Image/UploadFile',
    };
    const { data } = await axios(imgRequestOptions);
    newCampaignPhotoId = data;
  }

  const formData: FormData = objectToFormData(campaignPageDetails);
  if (newCampaignPhotoId) {
    formData.append('mainPhoto', newCampaignPhotoId);
  }

  const requestOptions: AxiosRequestConfig = {
    method: 'post',
    data: formData,
    headers: authHeader(),
    url: '/Campaigns/CreateCampaign',
  };
  const { data } = await axios(requestOptions);
  return data;
}

export async function deleteCampaign(campaignPageId: string) {
  const requestOptions: AxiosRequestConfig = {
    method: 'post',
    data: qs.stringify({ campaignPageId }),
    headers: authHeader(),
    url: '/Campaigns/DeleteCampaign',
  };
  const { data } = await axios(requestOptions);
  return data;
}
