import { GivingFrequencyCode, GivingFrequency } from '@root/enums';

export default (givingFrequencyCode: GivingFrequencyCode) => {
  const frequencyDetails = {
    showSingle: false,
    showMonthly: false,
    showAnnual: false,
    selectedFrequencyName: GivingFrequency.Single,
    durations: [],
  };
  switch (givingFrequencyCode) {
    case GivingFrequencyCode.SingleOnly:
      frequencyDetails.showSingle = true;
      frequencyDetails.showMonthly = false;
      frequencyDetails.showAnnual = false;
      frequencyDetails.selectedFrequencyName = GivingFrequency.Single;
      frequencyDetails.durations = [
        ...(frequencyDetails.showSingle
          ? [
              {
                id: 0,
                name: GivingFrequency.Single,
              },
            ]
          : []),
        ...(frequencyDetails.showMonthly
          ? [
              {
                id: 1,
                name: GivingFrequency.Monthly,
              },
            ]
          : []),
        ...(frequencyDetails.showAnnual
          ? [
              {
                id: 2,
                name: GivingFrequency.Annually,
              },
            ]
          : []),
      ];
      break;

    case GivingFrequencyCode.SingleDefaultAllowMonthly:
      frequencyDetails.showSingle = true;
      frequencyDetails.showMonthly = true;
      frequencyDetails.showAnnual = false;
      frequencyDetails.selectedFrequencyName = GivingFrequency.Single;
      frequencyDetails.durations = [
        ...(frequencyDetails.showSingle
          ? [
              {
                id: 0,
                name: GivingFrequency.Single,
              },
            ]
          : []),
        ...(frequencyDetails.showMonthly
          ? [
              {
                id: 1,
                name: GivingFrequency.Monthly,
              },
            ]
          : []),
        ...(frequencyDetails.showAnnual
          ? [
              {
                id: 2,
                name: GivingFrequency.Annually,
              },
            ]
          : []),
      ];
      break;

    case GivingFrequencyCode.SingleDefaultAllowMonthlyAllowAnnual:
      frequencyDetails.showSingle = true;
      frequencyDetails.showMonthly = true;
      frequencyDetails.showAnnual = true;
      frequencyDetails.selectedFrequencyName = GivingFrequency.Single;
      frequencyDetails.durations = [
        ...(frequencyDetails.showSingle
          ? [
              {
                id: 0,
                name: GivingFrequency.Single,
              },
            ]
          : []),
        ...(frequencyDetails.showMonthly
          ? [
              {
                id: 1,
                name: GivingFrequency.Monthly,
              },
            ]
          : []),
        ...(frequencyDetails.showAnnual
          ? [
              {
                id: 2,
                name: GivingFrequency.Annually,
              },
            ]
          : []),
      ];
      break;

    case GivingFrequencyCode.MonthlyOnly:
      frequencyDetails.showSingle = false;
      frequencyDetails.showMonthly = true;
      frequencyDetails.showAnnual = false;
      frequencyDetails.selectedFrequencyName = GivingFrequency.Monthly;
      frequencyDetails.durations = [
        ...(frequencyDetails.showSingle
          ? [
              {
                id: 0,
                name: GivingFrequency.Single,
              },
            ]
          : []),
        ...(frequencyDetails.showMonthly
          ? [
              {
                id: 1,
                name: GivingFrequency.Monthly,
              },
            ]
          : []),
        ...(frequencyDetails.showAnnual
          ? [
              {
                id: 2,
                name: GivingFrequency.Annually,
              },
            ]
          : []),
      ];
      break;

    case GivingFrequencyCode.MonthlyDefaultAllowSingle:
      frequencyDetails.showSingle = true;
      frequencyDetails.showMonthly = true;
      frequencyDetails.showAnnual = false;
      frequencyDetails.selectedFrequencyName = GivingFrequency.Monthly;
      frequencyDetails.durations = [
        ...(frequencyDetails.showSingle
          ? [
              {
                id: 0,
                name: GivingFrequency.Single,
              },
            ]
          : []),
        ...(frequencyDetails.showMonthly
          ? [
              {
                id: 1,
                name: GivingFrequency.Monthly,
              },
            ]
          : []),
        ...(frequencyDetails.showAnnual
          ? [
              {
                id: 2,
                name: GivingFrequency.Annually,
              },
            ]
          : []),
      ];
      break;

    case GivingFrequencyCode.MonthlyDefaultAllowAnnual:
      frequencyDetails.showSingle = false;
      frequencyDetails.showMonthly = true;
      frequencyDetails.showAnnual = true;
      frequencyDetails.selectedFrequencyName = GivingFrequency.Monthly;
      frequencyDetails.durations = [
        ...(frequencyDetails.showSingle
          ? [
              {
                id: 0,
                name: GivingFrequency.Single,
              },
            ]
          : []),
        ...(frequencyDetails.showMonthly
          ? [
              {
                id: 1,
                name: GivingFrequency.Monthly,
              },
            ]
          : []),
        ...(frequencyDetails.showAnnual
          ? [
              {
                id: 2,
                name: GivingFrequency.Annually,
              },
            ]
          : []),
      ];
      break;

    case GivingFrequencyCode.MonthlyDefaultAllowSingleAllowAnnual:
      frequencyDetails.showSingle = true;
      frequencyDetails.showMonthly = true;
      frequencyDetails.showAnnual = true;
      frequencyDetails.selectedFrequencyName = GivingFrequency.Monthly;
      frequencyDetails.durations = [
        ...(frequencyDetails.showSingle
          ? [
              {
                id: 0,
                name: GivingFrequency.Single,
              },
            ]
          : []),
        ...(frequencyDetails.showMonthly
          ? [
              {
                id: 1,
                name: GivingFrequency.Monthly,
              },
            ]
          : []),
        ...(frequencyDetails.showAnnual
          ? [
              {
                id: 2,
                name: GivingFrequency.Annually,
              },
            ]
          : []),
      ];
      break;

    case GivingFrequencyCode.AnnualOnly:
      frequencyDetails.showSingle = false;
      frequencyDetails.showMonthly = false;
      frequencyDetails.showAnnual = true;
      frequencyDetails.selectedFrequencyName = GivingFrequency.Annually;
      frequencyDetails.durations = [
        ...(frequencyDetails.showSingle
          ? [
              {
                id: 0,
                name: GivingFrequency.Single,
              },
            ]
          : []),
        ...(frequencyDetails.showMonthly
          ? [
              {
                id: 1,
                name: GivingFrequency.Monthly,
              },
            ]
          : []),
        ...(frequencyDetails.showAnnual
          ? [
              {
                id: 2,
                name: GivingFrequency.Annually,
              },
            ]
          : []),
      ];
      break;

    case GivingFrequencyCode.AnnualDefaultAllowMonthly:
      frequencyDetails.showSingle = false;
      frequencyDetails.showMonthly = true;
      frequencyDetails.showAnnual = true;
      frequencyDetails.selectedFrequencyName = GivingFrequency.Annually;
      frequencyDetails.durations = [
        ...(frequencyDetails.showSingle
          ? [
              {
                id: 0,
                name: GivingFrequency.Single,
              },
            ]
          : []),
        ...(frequencyDetails.showMonthly
          ? [
              {
                id: 1,
                name: GivingFrequency.Monthly,
              },
            ]
          : []),
        ...(frequencyDetails.showAnnual
          ? [
              {
                id: 2,
                name: GivingFrequency.Annually,
              },
            ]
          : []),
      ];
      break;

    case GivingFrequencyCode.AnnualDefaultAllowSingleAllowMonthly:
      frequencyDetails.showSingle = true;
      frequencyDetails.showMonthly = true;
      frequencyDetails.showAnnual = true;
      frequencyDetails.selectedFrequencyName = GivingFrequency.Annually;
      frequencyDetails.durations = [
        ...(frequencyDetails.showSingle
          ? [
              {
                id: 0,
                name: GivingFrequency.Single,
              },
            ]
          : []),
        ...(frequencyDetails.showMonthly
          ? [
              {
                id: 1,
                name: GivingFrequency.Monthly,
              },
            ]
          : []),
        ...(frequencyDetails.showAnnual
          ? [
              {
                id: 2,
                name: GivingFrequency.Annually,
              },
            ]
          : []),
      ];
      break;

    default:
      frequencyDetails.showSingle = true;
      frequencyDetails.showMonthly = true;
      frequencyDetails.showAnnual = true;
      frequencyDetails.selectedFrequencyName = GivingFrequency.Single;
      frequencyDetails.durations = [
        ...(frequencyDetails.showSingle
          ? [
              {
                id: 0,
                name: GivingFrequency.Single,
              },
            ]
          : []),
        ...(frequencyDetails.showMonthly
          ? [
              {
                id: 1,
                name: GivingFrequency.Monthly,
              },
            ]
          : []),
        ...(frequencyDetails.showAnnual
          ? [
              {
                id: 2,
                name: GivingFrequency.Annually,
              },
            ]
          : []),
      ];
      break;
  }

  return frequencyDetails;
};
