/* eslint-disable no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import qs from 'qs';
import { AppThunk } from '@app/AppThunk';

import {
  CampaignPageDetails,
  createCampaign,
  deleteCampaign,
  getCampaignPageDetails,
  getSupportMessages,
  BuyerDetails,
  CampaignPageCRUDViewModel,
} from '@api/campaign';
import { SupportDetails } from '@root/interfaces';
import { PageKind, GivingFrequency } from '@root/enums';
import { fetchUserProfile } from '@modules/user/user.slice';

const queryString = qs.parse(window.location.search, {
  ignoreQueryPrefix: true,
});

interface CampaignState {
  campaignPageDetails: CampaignPageDetails | null;
  buyerDetails: BuyerDetails | null;
  coverCosts: boolean;
  donationAmount: number;
  givingFrequency: GivingFrequency;
  giftAidClaimAmount: number;
  giftAidCheckbox: boolean;
  selectedCampaignPageId: string | null;
  selectedCampaignActionId: string | null;
  selectedPackageId: string | null;
  selectedDesignationOrderId: string | null;
  supportDetails: any | null;
  supportMessages: any | null;
  error: string | null;
  isLoading: boolean;
  loadingSupportMessages: boolean;
  childPageCreation: any;
  childPageDeletion: any;
  errorPage: any;
  team: Team | null;
  teamMember: TeamMember | null;
  individual: Individual | null;
  campaignId?: any;
  appealId?: any;
  packageId?: any;
  defaultDesignation?: any;
  queryString?: any;
  startFundraising?: boolean;
}

const initialState: CampaignState = {
  campaignPageDetails: null,
  buyerDetails: null,
  selectedCampaignPageId: null,
  selectedCampaignActionId: null,
  selectedPackageId: null,
  selectedDesignationOrderId: null,
  coverCosts: false,
  donationAmount: 1, // minimum donation amount set to 0 by default
  givingFrequency: GivingFrequency.Single,
  giftAidClaimAmount: 0,
  giftAidCheckbox: false,
  supportDetails: null,
  supportMessages: null,
  error: null,
  isLoading: false,
  loadingSupportMessages: false,
  childPageCreation: null,
  childPageDeletion: null,
  errorPage: null,
  team: { active: false, friendlyURL: null, masterURL: null },
  teamMember: {
    active: false,
    friendlyURL: null,
    teamURL: null,
    masterURL: null,
  },
  individual: {
    active: false,
    friendlyURL: null,
    masterURL: null,
  },
  campaignId: queryString.cmp ? queryString.cmp : null,
  appealId: queryString.apl ? queryString.apl : null,
  packageId: queryString.pck ? queryString.pck : null,
  defaultDesignation: queryString.des ? queryString.des : null,
  queryString,
  startFundraising: false,
};

export enum CampaignPageTypes {
  Master = 703650000,
  Individual,
  Team,
  TeamMember,
}

export interface Team {
  active: boolean;
  masterURL: string;
  friendlyURL: string;
}

export interface TeamMember {
  active: boolean;
  friendlyURL: string;
  teamURL: string;
  masterURL: string;
}

export interface Individual {
  active: boolean;
  friendlyURL: string;
  masterURL: string;
}

function startLoading(state: CampaignState) {
  state.isLoading = true;
}

const campaignSlice = createSlice({
  name: 'campaign',
  initialState,
  reducers: {
    /* Get page details */
    getCampaignPageDetailsStart: startLoading,
    getCampaignPageDetailsSuccess(
      state,
      action: PayloadAction<CampaignPageDetails>
    ) {
      state.campaignPageDetails = action.payload;
      state.error = null;
      state.errorPage = null;
      state.isLoading = false;
    },
    getCampaignPageDetailsFailure(state, action: PayloadAction<any>) {
      state.isLoading = false;
      state.error = action.payload;
      state.errorPage = '404_error';
    },

    /* Get support messages */
    getSupportMessagesStart: (state: CampaignState) => {
      state.loadingSupportMessages = true;
      state.supportMessages = null;
    },
    getSupportMessagesSuccess(state, action: PayloadAction<any>) {
      state.supportMessages = action.payload;
      state.error = null;
      state.loadingSupportMessages = false;
    },
    getSupportMessagesFailure: (
      state: CampaignState,
      action: PayloadAction<string>
    ) => {
      state.loadingSupportMessages = false;
      state.error = action.payload;
    },

    /* Create Campaign */
    createCampaignPageStart: startLoading,
    createCampaignPageSuccess(state, action: PayloadAction<any>) {
      state.childPageCreation = action.payload;
      state.error = null;
      state.isLoading = false;
    },
    createCampaignPageFailure: (
      state: CampaignState,
      action: PayloadAction<string>
    ) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    /* Delete Campaign */
    deleteCampaignPageStart: startLoading,
    deleteCampaignPageSuccess(state, action: PayloadAction<any>) {
      state.childPageDeletion = action.payload;
      state.error = null;
      state.isLoading = false;
    },
    deleteCampaignPageFailure: (
      state: CampaignState,
      action: PayloadAction<string>
    ) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    /* Set donation details */
    setBuyerDetails(state, action: PayloadAction<BuyerDetails>) {
      state.buyerDetails = action.payload;
    },
    setSelectedCampaignPageId(state, action: PayloadAction<string>) {
      state.selectedCampaignPageId = action.payload;
    },
    setSelectedCampaignActionId(state, action: PayloadAction<string>) {
      state.selectedCampaignActionId = action.payload;
    },
    setSelectedPackageId(state, action: PayloadAction<string>) {
      state.selectedPackageId = action.payload;
    },
    setSelectedDesignationOrderId(state, action: PayloadAction<string>) {
      state.selectedDesignationOrderId = action.payload;
    },
    setCoverCosts(state, action: PayloadAction<boolean>) {
      state.coverCosts = action.payload;
    },
    setDonationAmount(state, action: PayloadAction<number>) {
      state.donationAmount = action.payload;
    },
    setGivingFrequency(state, action: PayloadAction<GivingFrequency>) {
      state.givingFrequency = action.payload;
    },
    setGiftAidClaimAmount(state, action: PayloadAction<number>) {
      state.giftAidClaimAmount = action.payload;
    },
    setGiftAidCheckbox(state, action: PayloadAction<boolean>) {
      state.giftAidCheckbox = action.payload;
    },
    setSupportDetails(state, action: PayloadAction<SupportDetails>) {
      state.supportDetails = action.payload;
    },
    clearChildPageCreation(state) {
      state.childPageCreation = null;
    },
    setTeam(state: CampaignState, action: PayloadAction<Team>) {
      state.team = action.payload;
    },
    setTeamMember(state: CampaignState, action: PayloadAction<TeamMember>) {
      state.teamMember = action.payload;
    },
    setIndividual(state: CampaignState, action: PayloadAction<Individual>) {
      state.individual = action.payload;
    },
    setStartFundraising(state, action: PayloadAction<boolean>) {
      state.startFundraising = action.payload;
    },
  },
});

export const {
  getCampaignPageDetailsStart,
  getCampaignPageDetailsSuccess,
  getCampaignPageDetailsFailure,
  getSupportMessagesStart,
  getSupportMessagesSuccess,
  getSupportMessagesFailure,
  createCampaignPageStart,
  createCampaignPageSuccess,
  createCampaignPageFailure,
  deleteCampaignPageStart,
  deleteCampaignPageSuccess,
  deleteCampaignPageFailure,
  setBuyerDetails,
  setSelectedCampaignPageId,
  setSelectedCampaignActionId,
  setSelectedPackageId,
  setSelectedDesignationOrderId,
  setCoverCosts,
  setDonationAmount,
  setGivingFrequency,
  setGiftAidClaimAmount,
  setGiftAidCheckbox,
  setSupportDetails,
  clearChildPageCreation,
  setTeam,
  setTeamMember,
  setIndividual,
  setStartFundraising,
} = campaignSlice.actions;

export default campaignSlice.reducer;

export const fetchCampaignPageDetails =
  (friendlyUrl: string, defaultDesignation?: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getCampaignPageDetailsStart());
      const resp = await getCampaignPageDetails(
        friendlyUrl,
        defaultDesignation
      );
      if (resp.success) {
        dispatch(
          getCampaignPageDetailsSuccess({
            ...resp.data.campaignPageDetails,
            kind: PageKind.Campaign,
          })
        );
      } else {
        dispatch(getCampaignPageDetailsFailure(resp));
      }
    } catch (err) {
      dispatch(getCampaignPageDetailsFailure(err.toString()));
    }
  };

export const fetchSupportMessages =
  (friendlyUrl: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getSupportMessagesStart());
      const childCampaignPageDetails = await getSupportMessages(friendlyUrl);
      dispatch(getSupportMessagesSuccess(childCampaignPageDetails));
    } catch (err) {
      dispatch(getSupportMessagesFailure(err.toString()));
    }
  };

export const createNewCampaignPage =
  (
    campaignPageDetails: CampaignPageCRUDViewModel,
    campaignPhoto?: any
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createCampaignPageStart());
      const resp = await createCampaign(campaignPageDetails, campaignPhoto);
      dispatch(createCampaignPageSuccess(resp));
      dispatch(fetchUserProfile());
    } catch (err) {
      dispatch(createCampaignPageFailure(err.toString()));
    }
  };

export const deleteCurrentCampaignPage =
  (campaignPageId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(deleteCampaignPageStart());
      const resp = await deleteCampaign(campaignPageId);
      dispatch(deleteCampaignPageSuccess(resp));
      dispatch(fetchUserProfile());
    } catch (err) {
      dispatch(deleteCampaignPageFailure(err.toString()));
    }
  };
