import axios, { AxiosRequestConfig } from 'axios';
import { AdyenIntentRequest } from '@root/app/types';

export async function getClientCode() {
  const { data } = await axios.get('/home/getClientCode');
  return data.success ? data.data : 'default';
}

export async function getMembershipGroups() {
  const { data } = await axios.get('/donation/getMembershipGroups/');
  return data.success ? data.data : [];
}

export async function getCustomQuestions(pageId: string, pageKind: string) {
  const { data } = await axios.get(
    `/${pageKind}/getCustomQuestions/?id=${pageId}`
  );
  return data.success ? data.data : [];
}

export async function getConsentQuestions(pageId: string, pageKind: string) {
  const { data } = await axios.get(
    `/${pageKind}/getConsentCustomQuestions/?id=${pageId}`
  );
  return data.success ? data.data : [];
}

export async function getAddressAutocompleteResults(
  formData: FormData,
  pageName: string
) {
  const requestOptions: AxiosRequestConfig = {
    method: 'post',
    data: formData,
    url: `/${pageName}/ValidateAddress`,
  };
  const { data } = await axios(requestOptions);
  return data.success ? data : null;
}

export async function getProcessingResult(
  formData: FormData,
  pageKind: string
) {
  const requestOptions: AxiosRequestConfig = {
    method: 'post',
    data: formData,
    url: `/${pageKind}/Process`,
  };
  const { data } = await axios(requestOptions);
  return data;
}

export async function getPtxVerification(formData: FormData, pageKind: string) {
  const requestOptions: AxiosRequestConfig = {
    method: 'post',
    data: formData,
    url: `/${pageKind}/PtxVerificationRequest`,
  };
  const { data } = await axios(requestOptions);
  return data;
}

export async function getStripeIntentResult(
  formData: FormData,
  updateIntent: boolean
) {
  const requestOptions: AxiosRequestConfig = {
    method: 'post',
    data: formData,
    url: updateIntent
      ? `/StripePayment/StripeUpdateIntent `
      : `/StripePayment/StripeIntent`,
  };
  const { data } = await axios(requestOptions);
  return data.success ? data : null;
}

export async function getAdyenIntentResult(intent: AdyenIntentRequest) {
  const requestOptions: AxiosRequestConfig = {
    method: 'post',
    data: JSON.stringify(intent),
    url: `/api/AdyenPayment/CreateSession`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  const { data } = await axios(requestOptions);
  return data.success ? data : null;
}

export async function setApplicationInsightsLog(
  formData: FormData,
  pageKind: string
) {
  const requestOptions: AxiosRequestConfig = {
    method: 'post',
    data: formData,
    url: `/${pageKind}/setApplicationInsightsLog`,
  };
  const { data } = await axios(requestOptions);
  return data.success ? data : null;
}

export async function createPaymentLogObject(
  formData: FormData,
  pageKind: string
) {
  const requestOptions: AxiosRequestConfig = {
    method: 'post',
    data: formData,
    url: `/${pageKind}/createPaymentLogObject`,
  };
  const { data } = await axios(requestOptions);
  return data.success ? data : null;
}

export async function postRecaptchaResponse(token: string, pageKind: string) {
  const requestOptions: AxiosRequestConfig = {
    method: 'post',
    data: JSON.stringify(token),
    url: `/${pageKind}/postRecaptchaResponse`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  const { data } = await axios(requestOptions);
  return data.success ? data.data : [];
}

// export async function getRecaptchaResponse(token: string, pageKind: string) {
//   const { data } = await axios.get(
//     `/${pageKind}/getRecaptchaResponse/?token=${token}`
//   );
//   return data.success ? data.data : [];
// }
