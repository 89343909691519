import { formatValue } from 'currencyInputField';

export const getAmountFormatter = (
  valueAmount: string | number,
  locale,
  isoCurrencyCode,
  hideDecimalPlaces: boolean
) => {
  const europe = [
    { localeCode: 'de' },
    { localeCode: 'nl' },
    { localeCode: 'es' },
    { localeCode: 'fr' },
  ];

  const setAmount = valueAmount ? Number(valueAmount).toFixed(2) : '0';

  const value = formatValue({
    value: setAmount.toString(),
    intlConfig: {
      locale: locale,
      currency: isoCurrencyCode,
    },
  }).trim();

  // Determine what the decimal separator is
  const decimalSeparator = europe.find((x) => x.localeCode === locale)
    ? ','
    : '.';

  // Create Regex to check for digits and determine if there is a suffix
  const isDigit = `[0-9]`;
  const regIsDigit = new RegExp(isDigit, 'g');
  const hasSuffix = !regIsDigit.test(
    value.substring(value.lastIndexOf('\u00A0'), value.length)
  );

  // Get the amount from value
  let amountIndex;
  if (hasSuffix) {
    amountIndex = value.substring(0, value.lastIndexOf('\u00A0'));
  } else {
    amountIndex = value.replace(/[A-Z]/g, '');
  }

  //  If Suffix = Return only the currency symbol
  let suffix = hasSuffix ? value.substring(value.lastIndexOf('\u00A0')) : null;
  if (suffix) {
    suffix = suffix.replace(/[A-Z]/g, '');
  }

  // Create Regex to check for decimalSeparator
  const checkDecimalSeparator = `\\${decimalSeparator}`;
  const regDecimalSeparator = new RegExp(checkDecimalSeparator, 'g');
  const hasDecimalSeparator = regDecimalSeparator.test(value);

  // Find the dcimal index and length
  const decimalIndex = hasSuffix
    ? value.substring(
        value.lastIndexOf(decimalSeparator) + 1,
        value.lastIndexOf('\u00A0')
      )
    : value.substring(
        hasDecimalSeparator
          ? value.lastIndexOf(decimalSeparator) + 1
          : value.length
      );

  // Check number of decimal spaces
  let decimalPlace;
  if (hasDecimalSeparator && decimalIndex.length === 2) {
    decimalPlace = '';
  } else if (hasDecimalSeparator && decimalIndex.length === 1) {
    decimalPlace = '0';
  } else {
    decimalPlace = `${decimalSeparator}00`;
  }
  // Combine the values
  let formattedValue;
  if (hasSuffix) {
    formattedValue = hideDecimalPlaces
      ? amountIndex + suffix
      : amountIndex + decimalPlace + suffix;
    return formattedValue;
  }
  formattedValue = hideDecimalPlaces ? amountIndex : amountIndex + decimalPlace;
  return formattedValue;
};
