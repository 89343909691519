// ENGLISH AUSTRALIAN (en_AU)

export default {
  contactForm: {
    honour_dedication: 'In honor of...',
    memory_dedication: 'In memory of...',
    no_send_tribute: 'No,\nthank you',
    email_send_tribute: 'Yes,\nby email',
    mail_send_tribute: 'Yes,\nby mail',
    company: 'Company',
    company_help_text:
      'The Company or Organization name which will appear on the acknowledgement and/or receipt.',
    first_name: 'First Name',
    first_name_validation: 'Please enter your first name',
    last_name: 'Last Name',
    last_name_validation: 'Please enter your last name',
    email: 'Email',
    email_validation: 'Please enter a valid email address',
    phone: 'Phone',
    phone_validation:
      'Please enter a valid phone number without decimals or dashes',
    mobile: 'Mobile',
    mobile_validation:
      'Please enter a valid mobile number without decimals or dashes',
    address: 'Address 1',
    address_validation: 'Please enter your address',
    address_auto_complete_help_text:
      'Address 1 field has autocomplete activated. 5 characters are required before suggestions are provided.',
    suite: 'Suite',
    city: 'City',
    city_validation: 'Please enter your city',
    province: 'State',
    province_validation: 'Please enter your State',
    postal_code: 'Postal Code',
    postal_code_validation: 'Please enter a valid postal code',
    country: 'Country',
    country_validation: 'Please enter a valid country',
    dedication_checkbox:
      'Yes, my donation is in honour or in memory of someone',
    employer: 'My Employer',
    tribute_type: 'Would you like us to send a card?',
    addressee_name: 'Send Card To',
    addressee_name_validation: `Please enter recipient's name`,
  },
};
