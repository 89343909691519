import { useTranslation } from 'react-i18next';

export const p2pActionSet = (
  type,
  component,
  isTeam = false,
  isTeamMember = false,
  isIndividual = false
) => {
  const { t } = useTranslation();
  return {
    actionBtns: [
      {
        id: 'donate',
        campaignType: type,
        component: component,
        display: true,
        label:
          component === 'heading' ? t('donate_this_campaign') : t('donate_now'),
        title:
          (component === 'action' ||
            component === t('team_title_donate_now')) &&
          type === 'Team'
            ? t('team_title_donate_now')
            : t('title_donate_now'),
      },
      ...(type === 'Master' && component === 'heading'
        ? [
            {
              id: 'start',
              campaignType: type,
              component: component,
              display: type === 'Master' && component === 'heading',
              label: t('start_fundraiser'),
              title: t('title_start_fundraiser'),
            },
          ]
        : []),
      ...(type === 'Master' || type === 'Team'
        ? [
            {
              id: 'team',
              campaignType: type === 'Master' ? 'Master' : 'Team',
              component: component === 'heading' ? null : component,
              display:
                (type === 'Team' && component === 'ribbon') ||
                component === isTeam ||
                isTeamMember
                  ? t('title_view_team')
                  : t(`${type.toLowerCase()}_title_join_team`),
              label:
                isTeamMember && type === 'Team'
                  ? t('view_individual')
                  : (isTeamMember && type === 'Master' && t('view_team')) ||
                    t(`${type.toLowerCase()}_join_team`),
              title:
                isTeamMember && type === 'Team'
                  ? t('title_view_individual')
                  : (isTeamMember &&
                      type === 'Master' &&
                      t('title_view_team')) ||
                    t(`${type.toLowerCase()}_title_join_team`),
            },
          ]
        : []),
      ...(type === 'Master'
        ? [
            {
              id: 'individual',
              campaignType: 'Master',
              component: component === 'heading' ? null : component,
              display:
                (type === 'Master' && component === isTeamMember) ||
                isIndividual
                  ? t('title_view_individual')
                  : t('title_join_individual'),
              label:
                isTeamMember || isIndividual
                  ? t('view_individual')
                  : t('join_individual'),
              title:
                isTeamMember || isIndividual
                  ? t('title_view_individual')
                  : t('title_join_individual'),
            },
          ]
        : []),
      ...(type === 'Team' || type === 'Individual' || type === 'TeamMember'
        ? [
            {
              id: 'create',
              campaignType:
                type === 'Individual'
                  ? 'Individual'
                  : (type === 'TeamMember' ? 'TeamMember' : 'Team') || null,
              component: component,
              display:
                type !== 'Master' &&
                component !== 'heading' &&
                component !== 'ribbon',
              label: t('create_fundraiser'),
              title: t('title_start_fundraiser'),
            },
          ]
        : []),
    ],
  };
};
