import { enCA, enUS, enGB, enAU, fr, nl, nb, es, de } from 'date-fns/locale';

const locales = { enCA, enUS, enGB, enAU, fr, nl, nb, es, de };

export const getDateLocale = (languageCode: string) => {
  switch (languageCode) {
    case 'en-CA':
      return { locale: locales.enCA };
    case 'en-AU':
      return { locale: locales.enAU };
    case 'en-GB':
      return { locale: locales.enGB };
    case 'en-US':
      return { locale: locales.enUS };
    case 'fr':
      return { locale: locales.fr };
    case 'de':
      return { locale: locales.de };
    case 'nl':
      return { locale: locales.nl };
    case 'es':
      return { locale: locales.es };
    case 'no':
      return { locale: locales.nb };
    default:
      return { locale: locales.enCA };
  }
};
