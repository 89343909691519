import * as React from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '@app/RootState';
import { DonationPageType } from '@root/enums';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

const { lazy, Suspense } = React;
const Campaign = lazy(() => import('@modules/campaign'));
const User = lazy(() => import('@modules/user'));
const Event = lazy(() => import('@modules/event'));
const Donation = lazy(() => import('@modules/donation'));

// Add Tras-cur
export const TransactionCurrency = React.createContext({
  currencySymbol: '$',
  isoCurrencyCode: 'CAD',
  languageCode: 'en-CA',
});

const history = createBrowserHistory();
const localEnv =
  window.location.host === 'localhost:3000' ||
  window.location.host === 'localhost:8001';

function App() {
  const { t } = useTranslation();
  const root = document.getElementById('root');
  root.setAttribute('aria-label', `${t('aria_enter')}`);

  const { isLoading: appLoading, sentryKey } = useSelector(
    (state: RootState) => state.app
  );

  if (!localEnv && sentryKey) {
    const startOfKey = sentryKey.substring(0, sentryKey.lastIndexOf('/'));

    const endOfKey = sentryKey.substring(
      sentryKey.lastIndexOf('/'),
      sentryKey.lastIndexOf('/') + sentryKey.length
    );

    Sentry.init({
      dsn: `https://${startOfKey}@o866051.ingest.sentry.io${endOfKey}`,
      ignoreErrors: [
        'Non-Error exception captured',
        'Non-Error promise rejection captured',
        'ResizeObserver loop limit exceeded',
      ],
      integrations: [
        new Integrations.BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
        }),
      ],
    });
  }

  return appLoading ? null : (
    <Router history={history}>
      <Switch>
        <Suspense fallback={<div />}>
          <Route path="/user" component={User} />
          <Route path="/campaign/:friendlyUrl+" component={Campaign} />
          <Route path="/donation/:friendlyUrl" kind="DonationPage">
            <Donation donationPageType={DonationPageType.DP} />
          </Route>
          <Route path="/donationCaller/:friendlyUrl" kind="DonationPage">
            <Donation donationPageType={DonationPageType.OCP} />
          </Route>
          <Route path="/membership/:friendlyUrl" kind="DonationPage">
            <Donation donationPageType={DonationPageType.Membership} />
          </Route>
          <Route path="/subscription/:friendlyUrl" kind="DonationPage">
            <Donation donationPageType={DonationPageType.Subscription} />
          </Route>
          <Route path="/event/:friendlyUrl" component={Event} />
        </Suspense>
      </Switch>
    </Router>
  );
}

export default App;
