/* eslint-disable no-unused-vars */
export enum PreferenceQuestionFormatType {
  YesNo = 703650000,
  Checkbox,
  Declaration,
  FreeText,
  SelectionWithDetails,
  OptionSet,
  MultiSelectOptionSet,
  Date,
  DateTime,
  Consent,
  MessageOfSupport,
  HonourMemoryOfMailing,
}

export enum QuestionTypeCode {
  Consent = 703650000,
  Internal,
  Custom,
}

export enum YesNo {
  Yes = 703650000,
  No,
}
