export const dp = {
  frequencySelector: {
    donate_single: 'Donate\nOnce',
    donate_monthly: 'Donate\nMonthly',
    donate_annually: 'Donate\nAnnually',
    subscribe_single: 'Subscribe\nOnce',
    subscribe_monthly: 'Subscribe\nMonthly',
    subscribe_annually: 'Subscribe\nAnnually',
  },
};

export default dp;
