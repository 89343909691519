export const cp = {
  start_p2p_btn: 'Start Fundraising',
  // P2P START actionButtonSet and P2P Action
  title_donate_now: 'I want to Donate to this Campaign',
  team_title_donate_now: 'I want to Donate to this Team',
  title_start_fundraiser: 'I want to Start my own Fundraiser',
  title_join_team: 'I want to Fundraise as a Team',
  title_view_team: 'See your teams fundraising activity',
  title_join_individual: 'I want to Fundraise as an Individual',
  title_view_individual: 'See your fundraising activity',
  master_title_join_team: 'I want to Fundraise as a Team',
  team_title_join_team: 'I want to Join this Team',
  donate_this_campaign: 'Donate to this Campaign',
  donate_now: 'Donate\nNow',
  start_fundraiser: 'Start Fundraising Now',
  create_fundraiser: 'Create a\nFundraiser',
  master_join_team: 'Create a\nTeam',
  team_join_team: 'Join this\nTeam',
  view_team: 'View your\nteam page',
  join_individual: 'Join as an\nIndividual',
  view_individual: 'View your\npage',
  // END P2P actionButtonSet and P2P Action
  view_dashboard: 'View dashboard',
  donate_today: 'Donate\nToday',
  select_heading: 'Please select the gift you wish to give:',
  choose_details: `Choose the details you would like to display on the organization or fundraiser's page:`,
  required_pwd: 'Please Enter your password',
  pwd_match:
    'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character',
};

export default cp;
