import de from './de';
import de_CH from './de_CH';
import en_AU from './en_AU';
import en_CA from './en_CA';
import en_GB from './en_GB';
import en_US from './en_US';
import es from './es';
import fr_CA from './fr_CA';
import fr from './fr';
import nb_NO from './nb_NO';
import nl from './nl';

export default {
  de: { translation: de },
  'de-DE': { translation: de },
  'de-CH': { translation: de_CH },
  'en-AU': { translation: en_AU },
  'en-CA': { translation: en_CA },
  'en-GB': { translation: en_GB },
  'en-US': { translation: en_US },
  es: { translation: es },
  fr: { translation: fr },
  'fr-CA': { translation: fr_CA },
  'nb-NO': { translation: nb_NO },
  nl: { translation: nl },
};
