// DUTCH (NL)

export default {
  // START Aria translation
  aria_enter: 'Begin donatiesectie',
  aria_flow:
    'Start {{routeName}} sectie. Sectie {{sectionIndex}} van {{totalSteps}}',
  // END Aria translation

  _verification: 'Verificatie bezig...',
  _timeout: 'Er is een time-outfout opgetreden. Probeer het later opnieuw.',
  _confirmation_boundary_error: `Er is een fout opgetreden. Maar uw transactie is verwerkt en zal op uw afschrift verschijnen.`,
  _unable_loading_error: `Kan de inhoud momenteel niet laden.`,
  _configuration_error: 'Er is een configuratiefout opgetreden',
  _loading: 'Bezig met laden...',
  _loading_image: 'Afbeelding laden...',
  _select: 'Selecteer',
  _month: 'Maand',
  _year: 'Jaar',
  _or: 'Of',

  amount: '<1>{{currencySymbol}}{{amount}}</1>',

  processing: {
    donation_donate: 'Verwerken van jouw donatie...',
    donation_subscribe: 'Het verwerken van uw abonnement...',
    contact: 'Jouw contactgegevens bijwerken...',
    paymentInfo: 'Jouw betalingsgegevens bijwerken...',
    failure_message: 'Kan betaling niet verwerken',
    fetching_pages: "Inzamelingspagina's ophalen...",
    recaptcha_token_error:
      'Het ophalen van reCAPTCHA-token is momenteel niet gelukt. Probeer het later opnieuw.',
    recaptcha_failure:
      'Slecht verzoek, slecht recaptcha-token. Controleer uw gegevens nogmaals en probeer het opnieuw.',
  },

  image: {
    no_alt:
      'Er is een dynamische afbeelding geladen, maar er is geen alternatieve tekst weergegeven',
  },

  title: {
    how_often_donate: 'Hoe wil je doneren?',
    select_amount_donate: 'Selecteer het bedrag dat je wilt doneren:',
    select_amount_subscribe: `Je bent momenteel geabonneerd op het geven van:`,
    direct_your_gift: 'Hoe wil je je cadeau richten?',
    bank: 'Bank',
    basc: 'BACS Direct Debit',
    select_payment_method: 'Selecteer je betalingsmethode',
    wallets: 'Portefeuilles',
    apple_pay: 'Apple Pay',
    google_pay: 'Google Pay',
    card: 'Kaart',
    lifetimeGiving: 'Levenslang',
    givingHistory: 'Geschiedenis geven',
    thisYearGiving: 'Dit jaar',
    lastGiftAmount: 'Laatste geschenkbedrag',
    recurringGift: 'Terugkerende gift',
    creditCard: 'Kredietkaart',
    childRecurringGift: 'Terugkerende geschenkstatus voor kinderen',
    next: 'Volgende',
    last: 'Laatste',
    cardType: 'Kaarttype',
    expiry: 'Vervaldatum',
    Cancelled: 'Geannuleerd',
    gift_aid: 'Geschenkhulp',
    donor_details: 'Gegevens',
    subscribe_details: 'Abonneegegevens',
    payment_details: 'Betalingsdetails',
    additionalInfo: 'Extra informatie',
    membership: 'Lidmaatschap',
    show_support: 'Deel een ondersteuningsbericht',
    share_on: 'Delen op', // CP
    share_generosity: 'Deel jouw vrijgevigheid en inspireer anderen:',
    help_further: 'Wil je verder helpen?',
  },

  button: {
    other_btn: 'Ander bedrag',
    cancel_btn: 'annuleren',
    search_btn: 'Zoeken',
    new_gift_btn: 'Nieuw cadeau',
    create_new_contact_btn: 'Creëer een cadeau voor een nieuwe donateur',
    update_contact_info: 'Update contactgegevens',
    update_payment_info: 'Update betalingsgegevens',
    update_payment_schedule: 'Werk het betalingsschema bij',
    edit_btn: 'Bewerk',
    view_btn: 'Visie',
    share_btn: 'Delen',
    save_changes: 'Wijzigingen opslaan',
  },

  navigation: {
    next_btn: 'Volgende',
    prev_btn: 'Vorige',
    update_btn: 'Bijwerken',
    finish_btn: 'Voltooien',
    donate_btn: 'Doneer',
    subscribe_btn: 'Abonnement',
    membership_btn: 'Lidmaatschap',
    ptx_verification: 'Verifiëren',
    authorization:
      'Door deze stap te voltooien, geeft u toestemming voor het opslaan van deze kaartrekening voor: <strong><1>{{amount}}</1> / per <2>{{recurring}}</2>, tot annulering</strong>',
  },

  link: {
    read_more: 'Lees verder',
    show_less: 'Laat minder zien',
    donate_monthly_link: 'Ik zou graag maandelijks willen doneren',
    donate_annually_link: 'Ik zou graag jaarlijks willen doneren',
    privacy_policy: 'Privacybeleid',
    terms_conditions: 'Voorwaarden',
    your_donation: 'Jouw donatie <1>{{currency}}</1>',
    your_monthly_donation: 'Jouw maandelijkse donatie <1>{{currency}}</1>',
    your_annually_donation: 'Jouw jaarlijkse donatie <1>{{currency}}</1>',
    your_subscription: 'Jouw abonnement <1>{{currency}}</1>',
    your_monthly_subscription:
      'Jouw maandelijkse abonnement <1>{{currency}}</1>',
    your_annually_subscription:
      'Jouw jaarlijkse abonnement <1>{{currency}}</1>',
    your_membership: 'Jouw lidmaatschap <1>{{currency}}</1>',
    giving: 'Geven <1>{{currency}}</1>',
  },

  forms: {
    no: 'Nee',
    yes: 'Ja',
    required: 'Vereist',
  },

  contactForm: {
    honour_dedication: 'Ter ere van...',
    memory_dedication: 'Ter nagedachtenis van...',
    no_send_tribute: 'Nee,\ndank u',
    email_send_tribute: 'Ja,\nper e-mail',
    mail_send_tribute: 'Ja,\nper post',
    company_gift: 'Is dit een bedrijfsdonatie',
    company: 'Bedrijfsnaam',
    company_valication: 'Vul a.u.b. de bedrijfsnaam in',
    company_help_text:
      'De naam van het bedrijf of de organisatie die op de bevestiging en/of kwitantie zal verschijnen.',
    first_name: 'Voornaam',
    first_name_validation: 'Gelieve jouw voornaam in te vullen',
    last_name: 'Achternaam',
    last_name_validation: 'Gelieve jouw achternaam in te voeren',
    anonymous_help_text:
      'Dit zal jouw naam en donorinformatie verbergen voor alle openbare communicatie; het goede doel zal jouw informatie echter nog steeds ontvangen.',
    email: 'E-mail',
    email_validation: 'Gelieve een geldig e-mailadres in te geven',
    email_help_text_donate:
      'Jouw ontvangstbevestiging en/of belastingbewijs wordt hier per e-mail verzonden.',
    email_help_text_subscribe:
      'Elke bevestiging of mededeling over dit abonnement wordt hierheen verzonden.',
    phone: 'Telefoon',
    phone_validation:
      'Voer een geldig telefoonnummer in zonder decimalen of streepjes',
    mobile: 'Mobiel',
    mobile_validation:
      'Voer een geldig mobiel nummer in, zonder decimalen of streepjes',
    postal_code: 'Postcode',
    postal_code_validation: 'Voer een geldige postcode in',
    address: 'Adres 1',
    address_validation: 'Voer alstublieft jouw adres in',
    address_auto_complete_help_text:
      'In adres 1-veld is automatisch aanvullen geactiveerd. Er zijn 5 tekens vereist voordat er suggesties worden gegeven.',
    address2: 'Adres 2',
    city: 'stad',
    city_validation: 'Voer alstublieft jouw stad in',
    province: 'Provincie',
    province_validation: 'Voer alstublieft jouw provincie in',
    country: 'Land',
    country_validation: 'Voer een geldig land in',
    employer_matches_checkbox: 'Mijn werkgever matcht donaties',
    employer: 'Mijn werkgever',
    honouree_label: 'Honoree naam',
    honouree_validation: 'Voer een geldige naam in', // used for employer validation - missing?
    dedication_checkbox:
      'Ja, mijn donatie is ter ere of ter nagedachtenis van iemand',
    tribute_type: 'Wil je dat we een kaartje sturen?',
    recipient_email: 'Voer het e-mailadres van de ontvanger in',
    recipient_email_validation:
      'Voer het e-mailadres of fysiek adres van de ontvanger in',
    addressee_name: 'Verstuur kaart naar',
    addressee_name_validation: `Voer de naam van de ontvanger in`,
    special_message: 'Deel hier jouw speciale bericht. (Optioneel)',
    double_donation: 'Kijk of jouw bedrijf jouw donatie zal verdubbelen!',
  },

  questionsForm: {
    required_message: 'Antwoorden op de onderstaande vragen zijn vereist.', // Google translation
    custom_questions_error: 'Antwoorden op alle vragen zijn verplicht', // Google translation
  },

  paymentForm: {
    // Bank
    transit_no: 'Transit Number', // Google translation
    tran_no_validation: 'Voer een geldig transitnummer in', // Google translation
    routing_no:
      'Doorvoernummer en nummer van financiële instelling (8 cijfers)',
    routing_no_validation:
      'Doorvoernummer en nummer van financiële instelling Is benodigd',
    sort_code: 'Sorteercode', // Google translation
    sort_code_validation: 'Voer een geldige sorteercode in', // Google translation
    bsb_no: 'BSB-nummer', // Google translation
    bsb_no_validation: 'Voer een geldig BSB-nummer in', // Google translation
    bank_check: 'Bankcheque',
    bank_name: 'Banknaam',
    bank_name_validation: 'Banknaam is vereist',
    bank_name_help_text: `De banknaam mag niet langer zijn dan 18 tekens`,
    acc_no: 'Rekeningnummer',
    acc_no_validation: 'Rekeningnummer Is benodigd',
    inst_no: 'Instellingsnummer', // Google translation
    inst_no_validation: 'Vul een geldig instellingsnummer in', // Google translation
    // Credit Card
    card_number: 'Kaartnummer',
    card_validation: 'Voer een geldig creditcardnummer in',
    expiry_date_mm: 'MM',
    expiry_date_mm_validation:
      'Voer een geldige vervaldatum (MM) in. Het eerste cijfer is 0 voor maanden met één cijfer.',
    expiry_date_yy: 'JJ',
    expiry_date_yy_validation: 'Voer een geldige vervaldatum in (YY).',
    expiry_date_mmyy: 'MM/JJ',
    card_security_code: 'CVV',
    card_security_code_validation: 'Voer de CVV in',
    use_existing_credit_card: 'Bestaande creditcard gebruiken',
    // PTX
    ptx_verified_message: 'Validatie succesvol',
    adyen_error_message: `Er is een type betalingsfout opgetreden: {{ resultCode }}. Controleer jouw gegevens nogmaals of selecteer een andere betalingsmethode.`,
  },

  frequencySelector: {
    donate_single: 'Doneer\neenmalig',
    donate_monthly: 'Doneer\nmaandelijks',
    donate_annually: 'Doneer\njaarlijks',
    subscribe_single: 'Eenmalig\nabonnement',
    subscribe_monthly: 'Maandelijks\nabonneren',
    subscribe_annually: 'Abonneer u\njaarlijks',
  },

  amountSelector: {
    minimum_donation_of: 'Minimale donatie van',
  },

  designationSelector: {
    greatest_need: 'Grootste behoefte',
    selection_label: 'Anders',
    order_label: 'Selecteer alstublieft',
  },

  giftAid: {
    giftAid_donate_checkbox:
      'Ja, ik wil graag een gift van <1>{{gift}}</1> (25% van de donatie) toevoegen bovenop mijn <2>{{donation}}</2> donatie.',
    giftAid_subscribe_checkbox:
      'Ja, ik betaal belasting in Groot-Brittannië en ik wil dit meer betekenen met Gift Aid.',
  },

  confirmation: {
    thank_you: 'Bedankt, {{name}}',
    donation_received_OCP: 'Jouw donatie is ontvangen.',
    donation_received:
      'Jouw donatie van <1>{{donationAmount}}</1> is ontvangen.',
    subscription_received: `Din abonnement på <1>{{donationAmount}}</1> har blitt mottatt.`,
    receipt: 'Er wordt een bevestiging verzonden naar <em>{{email}}</em>.',
    redirectMessage: 'U wordt doorgestuurd naar:',
    seconds: 'seconden',
  },

  // CampaignStats
  // src\components\molecules\campaignStats\index.tsx
  amount_raised: 'verhoogd', // CP
  goal: 'Doel', // CP
  of_goal: 'van het doel', // CP
  supporters: 'Supporters', // CP
  days_left: 'Dagen over', // CP

  Amount: 'Bedrag',
  Name: 'Naam',
  Team: 'Team',
  Total: 'Totaal',

  individual_subtitle: 'Start hieronder jouw individuele inzamelingsactie.',
  team_subtitle: 'Start hieronder jouw teaminzamelingsactie.',
  campaign_title: 'Laten we gaan!',

  individual_page_name_label: 'Naam individuele inzamelingspagina',
  team_page_name_label: 'Naam van de inzamelingspagina van het team',
  team_member_page_name_label: 'Naam inzamelingspagina teamlid',
  individual_page_goal_label: 'Individueel fondsenwervingsdoel',
  team_page_goal_label: 'Team Fondsenwervingsdoel',
  team_member_page_goal_label: 'Fondsenwervingsdoel teamlid',

  team_campaign_name: 'Naam van teaminzamelingsactie',
  campaign_name: 'Naam fondsenwervingspagina',
  campaign_name_validation: 'Voer een geldige campagnenaam in',
  campaign_goal: 'Fondsenwervingsdoel',
  team_campaign_goal: 'Teaminzamelingsdoel',
  campaign_url: 'Campagne-URL',

  create_campaign_btn: 'Maak een inzamelingspagina',
  update_campaign_btn: 'Wijzigingen opslaan',
  delete_campaign_btn: 'Verwijder deze pagina',
  cancel_changes_btn: 'Stop veranderingen',

  password: 'Wachtwoord',
  password_validation: 'Voer jouw wachtwoord in',
  password_reset_success:
    'Instructies voor het opnieuw instellen van het wachtwoord zijn verzonden naar {{email}}.',
  forgot_password: 'Wachtwoord vergeten?',
  old_password: 'Oud Wachtwoord',
  new_password: 'nieuw paswoord',
  forgot_password_title: 'Wachtwoord opnieuw instellen',
  forgot_password_btn: 'Stuur reset-instructies',
  change_password_title: 'Wachtwoord wijzigen',
  failed_password_change: 'Het oude wachtwoord komt niet overeen',
  success_password_change: 'Wachtwoord succesvol bijgewerkt',

  sign_in: 'Log in',
  sign_up: 'Aanmelden',
  start_your: 'Start je inzamelingsactie!',
  dont_have: 'Nog geen account?',
  already_have: 'Heb je al een account?',
  go_back: 'Ga terug',
  edit_campaign: 'Campagne bewerken',
  log_out: 'Uitloggen',
  activity: 'Activiteit',
  settings: 'Instellingen',

  // EditCampaignBox
  // src\components\organism\editCampaignBox\index.tsx
  characters_remaining: '{{count}} tekens resterend',
  start_a_campaign_btn: 'Start een campagne',
  campaign_photo: 'Afbeelding van de fondsenwervingspagina',
  recommended_photo_size: 'Aanbevolen fotoformaat: 1240 x 600 pixels',
  edit_profile_title: 'Profiel bewerken / bijwerken',
  edit_campaign_title_individual: 'Individuele inzamelingspagina bewerken',
  edit_campaign_title_team: 'Pagina voor teaminzamelingsactie bewerken',
  edit_campaign_title_team_member: 'Bewerk teamlid Fondsenwervingspagina',
  return_back_btn: 'Ga terug naar profiel',
  changes_saved_btn: 'Wijzigingen opgeslagen!',
  no_changes_btn: 'Geen wijzigingen om op te slaan',
  browse: 'bladeren',
  drag_drop_pic: 'Sleep een afbeelding om te uploaden',
  cancel_image_upload: 'Upload afbeelding annuleren',

  // DeleteCampaignModal
  // src\components\atoms\deleteCampaignModal\index.tsx
  deletion_confirmation_question:
    'Weet je zeker dat je deze inzamelingspagina wilt verwijderen?',

  // P2pRibbon
  // src\components\molecules\p2pRibbon\index.tsx
  join_team_btn: 'Kom bij het team',

  // CampaignForm
  // src\components\organism\campaignForm\index.tsx
  join_team_subtitle: 'Doe mee met {{name}}',
  join_as_individual_btn: 'Doe mee als individu',
  create_a_team_btn: 'Creëer een team',
  create_a_new_team_btn: 'Maak een nieuw team aan',
  welcome: 'Welkom',
  individual_select_message:
    'Selecteer of je geld wilt inzamelen voor een team of bekijk je profiel.',
  member_select_message:
    'Selecteer of je geld wilt inzamelen voor een nieuw team of bekijk je profiel.',
  team_select_message: 'Controleer jouw profiel.',
  ind_or_team_message:
    'Selecteer of je individueel geld wilt inzamelen of een fondsenwervingsteam wilt maken.',
  click_here: 'Klik hier',
  prefer_individual: 'Als je liever een teaminzamelingsactie wilt starten,',
  prefer_team: 'Als je liever een individuele inzamelingsactie wilt starten,',

  // CampaignSupportersDetails
  // src\components\organism\campaignSupportersDetails\index.tsx
  donated_by: 'Gedoneerd door',
  campaign_supporters: 'Aanhangers van de campagne',
  campaign_sponsor: 'Onze gulle sponsors en partners',

  // ShowSupportDetails
  // src\components\organism\showSupportDetails\index.tsx
  display_name:
    'Voer hier een weergavenaam in - deze verschijnt op de inzamelingspagina',
  leave_message:
    'Deel jouw ondersteuningsbericht - dit wordt weergegeven op de pagina van de organisatie of fondsenwervers, zodat ze weten dat ze een geschenk van u hebben ontvangen (optioneel)',
  support_display_all: 'Geef mijn naam, bericht en donatiebedrag weer.',
  support_hide_amount:
    'Geef alleen mijn naam en bericht weer (verbergt het donatiebedrag).',
  support_hide_name:
    'Geef alleen mijn donatiebedrag en bericht weer (verbergt naam).',
  support_hide_all:
    'Nee, verberg alstublieft mijn naam, bericht en donatiebedrag.',
  email_exists: 'dit email adres is al geregistreerd',

  anonymous: 'Anoniem',
  fundraising_team_for: 'Fondsenwervingsteam voor:',
  fundraising_for: 'Fondsenwerving voor:',
  fundraising_team_member: 'Lid van:',

  // LeaderboardsDetails
  // src\components\organism\leaderboardsDetails\index.tsx
  team_members: 'Leden van het team',
  no_pages_to_display: "Geen inzamelingspagina's om weer te geven.",
  fundraising_teams: 'Fondsenwervingsteams',
  individual_fundraisers: 'Individuele inzamelingsacties',

  // CampaignRow
  // src\components\molecules\campaignRow\index.tsx
  individual_member_of: 'Individueel lid van:',
  team_member_of: 'Teamlid van:',
  role_team_member: '(Rol: teamlid)',
  role_team_captain: '(Rol: teamcaptain)',

  // CampaignsListBox
  // src\components\molecules\campaignsListBox\index.tsx
  no_individual_pages: "Je hebt geen individuele inzamelingspagina's.",
  no_team_pages: "Je hebt geen inzamelingspagina's voor teams.",

  // ContactSearch
  // src\components\molecules\contactSearch\index.tsx
  constituentNumber: 'Componentnummer',
  update_saved_message: 'Update succesvol opgeslagen',

  // ErrorPage
  // src\components\organism\errorPage\index.tsx
  title_404: '404',
  subtitle_404: 'Sorry, we konden jouw pagina niet vinden.',
  message_404: 'Breng me terug naar -',
  home_page: 'Startpagina',

  // OCPLayout
  // src\components\layouts\ocpLayout\index.tsx
  new_gift_title: 'Een nieuw cadeau creëren',

  // PaymentSchedule
  // src\components\pages\PaymentSchedule.tsx
  cancel_donation: 'Donatie annuleren',
  back: 'Terug',
  notPaymentScheduleMessage: 'Geen betalingsschema beschikbaar',

  // OCPGate
  // src\components\pages\OCPGate.tsx
  start_by: 'Begin met het invoeren van een',

  // CancelPaymentSchedule
  // src\components\molecules\cancelPaymentSchedule\index.tsx
  note: 'Opmerking:',
  FeltPressure: 'Donor voelde druk van fondsenwerving',
  NoLongerAfford: 'Kan het zich niet langer veroorloven',
  UnderMinimumWage: 'Onder de minimumleeftijd',
  SpouseObjected: 'Echtgenoot maakte bezwaar',
  ChangedMind: 'Van gedachte veranderd',
  IntendedShortTerm: 'Donor bedoeld voor een korte termijn schenking',
  IntendedOneTime: 'Donor was van plan een eenmalige donatie te doen',
  Deceased: 'Overleden',
  Misunderstood: 'Onbegrepen toewijding',
  OtherCharities: 'Doneren aan andere goede doelen',
  PaymentDeclined: 'Betaling geweigerd',
  Complaint: 'Klacht',
  AccountClosed: 'Account gesloten',
  AlreadyDonating: 'Al aan het doneren',
  NoReason: 'Geen reden gegeven',

  // TimeoutModal CP
  // src\components\molecules\timeoutModal\index.tsx
  continue_session: 'Houd me aangemeld',
  session_timeout: 'Time-out sessie',
  session_message:
    'De huidige sessie verloopt over <1>{{countdown}}</1> seconden.',
  continue_confirmation: 'wil je verder gaan?',

  // DonateBox & DonateButton CP
  master_donate_btn: 'Doneer aan deze campagne',
  team_donate_btn: 'Doneer aan het team',
  team_member_donate_btn: 'Doneer aan de inzamelingsactie van {{name}}',

  // CampaignSupportersCard & Card
  // src\components\molecules\campaignSupportersCard\index.tsx
  // src\components\atoms\card\index.tsx
  donation_through: 'De schenking is gedaan',
  donation: 'Bijdrage',

  // OCP DonationGroup > Accordion
  why_donate_monthly: 'Welke impact heeft een maandelijkse donatie?',

  // ContactHistory
  // src\components\molecules\contactHistory\index.tsx
  Completed: 'Voltooid',
  Terminated: 'Geannuleerd',
  Failed: 'Mislukt',
  TransactionRefund: 'Terugbetaling',
  InProgress: 'Bezig',

  // UpdatePaymentInfo
  // src\components\pages\UpdatePaymentInfo.tsx
  notPaymentMethodsMessage: 'Geen betalingsmethode beschikbaar',

  // MembershipDetails
  // src\components\organism\membershipDetails\index.tsx
  include_membership_checkbox:
    'Betrek mijn lidmaatschap bij mijn <1>{{donation}}</1> <2>{{donationFrequency}}</2> donatie.',
  membership_order_label: 'Selecteer (optioneel)',

  // HelpFurther
  // src\components\molecules\helpFurther\index.tsx
  get_involved_title: 'Raak betrokken',
  set_up_fundraising_btn: 'Zet mijn inzamelingsactie op',
  start_fundraising_accordian_title: 'Steun een campagne',

  // OrderSummary
  // src\components\atoms\orderSummary\index.tsx
  summary_link_donate_description: `Klik op 'Jouw donatie' om het bedrag van jouw gift te wijzigen`,
  summary_link_subscribe_description: `Klik op 'Je abonnement' om het bedrag te wijzigen`,
  unexpected_error_message:
    'Er is een fout opgetreden. Maar jouw transactie is verwerkt en zal op jouw afschrift verschijnen. <1>Probeer het niet opnieuw.</1><br /><br /><2>Klik hier</2> of neem onmiddellijk contact op met ons donorserviceteam om te laten weten dat u dit bericht heeft ontvangen.',
  applePay_error: `Apple Pay is alleen beschikbaar indien ingeschakeld met iOS-compatibele apparaten (iPhone/iPad/Mac).`,
  googlePay_error:
    'GooglePay is niet ingeschakeld en/of beschikbaar op dit apparaat.',

  // AdditionalInfoDetails
  // src\components\organism\additionalInfoDetails\index.tsx
  additional_info_msg:
    'Als u opmerkingen heeft over jouw donatie, kunt u deze hier vermelden.',
  notes_or_direction:
    'Deel hieronder eventuele aanvullende opmerkingen of aanwijzingen met betrekking tot jouw donatie',
  nols_additional_info_msg:
    'Als u opmerkingen heeft over jouw donatie, kunt u deze hier vermelden.',
  nols_notes_or_direction: '',

  // SignInForm
  // src\components\organism\signInForm\index.tsx
  add_profile_pic: 'Voeg je profielfoto toe:',
  upload_profile_picture_checkbox: 'Ik wil graag een profielfoto uploaden',

  // SeachBar
  // src\components\atoms\searchBar\index.tsx
  sort_by: 'sorteer op',
  top_fundraisers: 'Top inzamelingsacties',
  most_recent: 'Meest recente',
  alphabetical: 'Alfabetisch',

  // CAMPAIGN PAGES!!!!!!!!!!
  // ProfileImg
  // src\components\atoms\profileImg\index.tsx
  change_profile_picture: 'Profielfoto veranderen',
  start_p2p_btn: 'Begin met fondsenwerving',
  // P2P START actionButtonSet and P2P Action
  title_donate_now: 'Ik wil doneren aan deze campagne',
  team_title_donate_now: 'Ik wil doneren aan dit team',
  title_start_fundraiser: 'Ik wil mijn eigen inzamelingsactie starten',
  title_join_team: 'Ik wil als team geld inzamelen',
  title_view_team: 'Bekijk de inzamelingsactie van jouw team',
  title_join_individual: 'Ik wil als particulier geld inzamelen',
  title_view_individual: 'Jouw inzamelingsactie bekijken',
  master_title_join_team: 'Ik wil als team geld inzamelen',
  team_title_join_team: 'Ik wil lid worden van dit team',
  donate_this_campaign: 'Doneer aan deze campagne',
  donate_now: 'Doneer\nnu',
  start_fundraiser: 'Begin nu met fondsenwerving',
  create_fundraiser: 'Maak een\ninzamelingsactie',
  master_join_team: 'Maak een\nteam',
  team_join_team: 'Word lid van\ndit team',
  view_team: 'Bekijk je\nteampagina',
  join_individual: 'Doe mee als\nindividu',
  view_individual: 'Bekijk je\npagina',
  // END P2P actionButtonSet and P2P Action
  view_dashboard: 'Bekijk dashboard',
  donate_today: 'Doneer\nVandaag',
  select_heading: 'Selecteer het cadeau dat je wilt geven:',
  choose_details: `Kies de details die je wilt weergeven op de pagina van de organisatie of de inzamelingsactie:`,
  required_pwd: 'Voer jouw wachtwoord in',
  pwd_match:
    'Moet 8 tekens bevatten, één hoofdletter, één kleine letter, één cijfer en één speciaal teken',
  // END CAMPAIGN PAGES!!!!!!!!!!
};
