import getSymbolFromCurrency from 'currency-symbol-map';

export const getCurrencyFormats = ({
  languageCode,
  currencySymbol,
  isoCurrencyCode,
}) => {
  const currencyFormat = {
    thousandSeparator: ',',
    decimalSeparator: '.',
    prefix: null,
    suffix: null,
  };

  switch (languageCode) {
    case 'en-CA':
    case 'en-AU':
    case 'en-GB':
    case 'en-US':
      currencyFormat.thousandSeparator = ',';
      currencyFormat.decimalSeparator = '.';
      currencyFormat.prefix = currencySymbol;
      break;
    case 'fr':
      currencyFormat.thousandSeparator = ' ';
      currencyFormat.decimalSeparator = ',';
      if (isoCurrencyCode === 'CAD') {
        currencyFormat.suffix = ` ${getSymbolFromCurrency(isoCurrencyCode)}`;
        break;
      }
      currencyFormat.suffix = ` ${currencySymbol}`;
      break;
    case 'de':
      currencyFormat.thousandSeparator = '';
      currencyFormat.decimalSeparator = ',';
      currencyFormat.suffix = ` ${currencySymbol}`;
      break;
    case 'nl':
      currencyFormat.thousandSeparator = '';
      currencyFormat.decimalSeparator = ',';
      currencyFormat.prefix = `${currencySymbol} `;
      break;
    case 'es':
      currencyFormat.thousandSeparator = ',';
      currencyFormat.decimalSeparator = '.';
      currencyFormat.prefix = currencySymbol;
      break;
    default:
      currencyFormat.thousandSeparator = ',';
      currencyFormat.decimalSeparator = '.';
      currencyFormat.prefix = currencySymbol;
      break;
  }
  return currencyFormat;
};
