/* eslint-disable no-unused-vars */
export enum PageKind {
  Donation,
  Campaign,
  Event,
}

/* eslint-disable no-unused-vars */
export enum PageBackgroundType {
  URL,
  Static,
  Path,
}

/* eslint-disable no-unused-vars */
export enum DonationPageType {
  DP = 703650000,
  OCP,
  Membership,
  Subscription,
}

/* eslint-disable no-unused-vars */
export enum PagePlacement {
  Left = 703650000,
  Right,
}
