/* eslint-disable no-unused-vars */
export enum FrequencyType {
  Daily = 844060000,
  Weekly = 844060001,
  Monthly = 844060002,
  Annually = 844060003,
}

export enum GivingFrequencyCode {
  SingleOnly = 703650000,
  s = 703650000,
  SingleDefaultAllowMonthly = 703650001,
  sm = 703650001,
  MonthlyDefaultAllowSingle = 703650002,
  ms = 703650002,
  MonthlyOnly = 703650003,
  m = 703650003,
  SingleDefaultAllowMonthlyAllowAnnual = 703650004,
  sma = 703650004,
  MonthlyDefaultAllowSingleAllowAnnual = 703650005,
  msa = 703650005,
  AnnualDefaultAllowSingleAllowMonthly = 703650006,
  asm = 703650006,
  MonthlyDefaultAllowAnnual = 703650007,
  ma = 703650007,
  AnnualDefaultAllowMonthly = 703650008,
  am = 703650008,
  AnnualOnly = 703650009,
  a = 703650009,
}

export enum GivingFrequency {
  Single = 1,
  Monthly,
  Annually,
}
