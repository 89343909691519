/* eslint-disable no-unused-vars */
export enum CancellationCode {
  FeltPressure = 844060000,
  NoLongerAfford,
  Misunderstood,
  UnderMinimumWage,
  SpouseObjected,
  ChangedMind,
  IntendedShortTerm,
  IntendedOneTime,
  Deceased,
  OtherCharities,
  PaymentDeclined,
  Complaint,
  AccountClosed,
  AlreadyDonating,
  NoReason,
}

export enum CommunicationApplicationCode {
  Email = 703650000,
  Print,
  Phone,
}

export enum CustomerType {
  Account = 1,
  Contact = 2,
}

export enum StatusCode {
  Completed = 844060000,
  Terminated = 844060001, // = cancelled
  Failed = 844060003,
  TransactionRefund = 844060004, // = refund
  InProgress = 844060005,
}

export enum DetailChange {
  Yes = 1,
  No = 0,
}

export enum ThemeFontType {
  Primary = 1,
  Secondary = 2,
  All = 3,
}
