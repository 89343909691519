export { authHeader } from './auth-header';
export { p2pActionSet } from './p2pActionSet';
export { getDateLocale } from './dateLocale';
export { getAmountFormatter } from './amountFormatter';
export { getCurrencyFormats } from './getCurrencyFormats';
export { getOrderTotal } from './getOrderTotal';
export { addressToBilling } from './addressToBilling';
export { default as getDefaultFrequencyDetails } from './getDefaultFrequencyDetails';

export const objectToFormData = (obj, formData = null, namespace = null) => {
  const fd = formData || new FormData();
  let formKey;

  Object.keys(obj).forEach((property) => {
    if (Object.prototype.hasOwnProperty.call(obj, property)) {
      formKey = namespace ? `${namespace}[${property}]` : property;
      // If property is an object, use recursively
      if (typeof obj[property] === 'object' && obj[property]) {
        objectToFormData(obj[property], fd, property);
      } else {
        fd.append(formKey, obj[property]);
      }
    }
  });
  return fd;
};
