import { GivingFrequency } from '@root/enums';
import { GetOrderTotal } from '../api/campaign';
import { OrderAmounts } from '../app/types';

export const getOrderTotal = ({
  coverCosts,
  pageDetails,
  donationAmount,
  selectedMembership,
  givingFrequency,
}: GetOrderTotal) => {
  const orderAmounts: OrderAmounts = {
    coverCostsAmount: 0,
    membershipAmount: 0,
    donationAmount: donationAmount || 0,
    fullOrderAmount: 0,
  };

  let costAmount = 0;
  let costPercentage = 0;

  if (pageDetails) {
    costAmount = pageDetails.coverCostAmount || 0;
    costPercentage = pageDetails.coverCostPercentage || 0;

    // Set membership amount
    if (selectedMembership && selectedMembership.membership) {
      orderAmounts.membershipAmount +=
        givingFrequency !== GivingFrequency.Single
          ? Math.round(
              (selectedMembership.membershipBaseAmount / 12 + Number.EPSILON) *
                100
            ) /
              100 +
            Math.round(
              (selectedMembership.membershipAmountTax / 12 + Number.EPSILON) *
                100
            ) /
              100
          : Math.round(
              (selectedMembership.membershipBaseAmount + Number.EPSILON) * 100
            ) /
              100 +
            Math.round(
              (selectedMembership.membershipAmountTax + Number.EPSILON) * 100
            ) /
              100;
    }

    // Set Cover costs amount
    if (coverCosts) {
      const amount =
        orderAmounts.donationAmount + orderAmounts.membershipAmount;
      const percentage = costPercentage / 100;
      orderAmounts.coverCostsAmount += amount * percentage + costAmount;
      orderAmounts.coverCostsAmount =
        Math.round((orderAmounts.coverCostsAmount + Number.EPSILON) * 100) /
        100;
    }

    // Set Full order Amount
    orderAmounts.fullOrderAmount =
      orderAmounts.coverCostsAmount +
      orderAmounts.membershipAmount +
      orderAmounts.donationAmount;
    orderAmounts.fullOrderAmount =
      Math.round((orderAmounts.fullOrderAmount + Number.EPSILON) * 100) / 100;
  }

  return orderAmounts;
};
